import * as React from 'react';
import {
    Card,
    FormLayout,
    Spinner,
    Stack,
    TextField,
    Icon,
    Button,
    ButtonGroup,
    Layout,
} from '@shopify/polaris';

import regexIsInvalid from '../utils/regexIsInvalid';
import RegexPatterns from '../utils/RegexPatterns';

const queryParams = window.location.search;
const shop = new URLSearchParams(queryParams).get('shop');

const RedirectsList = props => {
    if (props.initialLoad === true) {
        return (
            <Card sectioned>
                <Stack distribution='center'>
                    <Spinner color='teal' />
                </Stack>
            </Card>
        );
    } else if (props.redirects.length > 0) {
        return (
            <Card title='Redirects' sectioned>
                {props.redirects.map((redirect, index) => (
                    <Redirect
                        key={index}
                        index={index}
                        isLoading={props.isLoading}
                        redirect={redirect}
                        changeRedirect={props.changeRedirect}
                        deleteRedirect={props.deleteRedirect}
                        handleChange={props.handleChange}
                    />
                ))}
            </Card>
        );
    } else {
        return (
            <Card sectioned>
                <p>No existing redirects found.</p>
            </Card>
        );
    }
};

class Redirect extends React.Component {
    state = {
        editing: false,
    };

    render() {
        if (this.state.editing === true) {
            return (
                <Card.Section>
                    <FormLayout>
                        <Stack alignment='trailing'>
                            <Stack.Item>
                                <TextField
                                    label='Search Term'
                                    labelHidden
                                    readOnly
                                    value={this.props.redirect.RowKey}
                                    onChange={this.props.handleChange(this.props.index, 'RowKey')}
                                />
                            </Stack.Item>
                            <Stack.Item fill>
                                <TextField
                                    label='Redirect URL'
                                    labelHidden
                                    value={this.props.redirect.RedirectUrl}
                                    onChange={this.props.handleChange(
                                        this.props.index,
                                        'RedirectUrl',
                                    )}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <ButtonGroup segmented>
                                    <Button
                                        primary
                                        onClick={() => {
                                            this.props.changeRedirect(
                                                this.props.redirect.RowKey,
                                                this.props.redirect.RedirectUrl,
                                            );
                                            this.setState({ editing: false });
                                        }}
                                        loading={this.props.isLoading}
                                        disabled={this.props.isLoading}
                                    >
                                        Save
                  </Button>
                                </ButtonGroup>
                            </Stack.Item>
                        </Stack>
                    </FormLayout>
                </Card.Section>
            );
        } else {
            return (
                <Card.Section>
                    <FormLayout>
                        <Stack alignment='trailing'>
                            <Stack.Item>
                                <TextField
                                    label='Search Term'
                                    labelHidden
                                    readOnly
                                    value={this.props.redirect.RowKey}
                                />
                            </Stack.Item>
                            <Stack.Item fill>
                                <TextField
                                    label='Redirect URL'
                                    labelHidden
                                    readOnly
                                    value={this.props.redirect.RedirectUrl}
                                    pattern={RegexPatterns.url}
                                    error={
                                        regexIsInvalid(
                                            this.props.redirect.RedirectUrl,
                                            RegexPatterns.url,
                                        )
                                            ? 'Must be a valid URL, including protocol (https)'
                                            : ''
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <ButtonGroup segmented>
                                    <Button
                                        disabled={this.props.isLoading}
                                        onClick={() => this.setState({ editing: true })}
                                    >
                                        Edit
                  </Button>
                                    <Button
                                        destructive
                                        disabled={this.props.isLoading}
                                        loading={this.props.isLoading}
                                        onClick={() =>
                                            this.props.deleteRedirect(this.props.redirect.RowKey)
                                        }
                                    >
                                        <Icon source='delete' />
                                    </Button>
                                </ButtonGroup>
                            </Stack.Item>
                        </Stack>
                    </FormLayout>
                </Card.Section>
            );
        }
    }
}

export default class Redirects extends React.Component {
    state = {
        initialLoad: true,
        isLoading: false,
        redirects: [],
        searchTermToAdd: '',
        redirectUrlToAdd: '',
        addingRedirect: false,
    };

    componentDidMount() {
        this.getRedirects();
    }

    getRedirects = () => {
        this.setState({ isLoading: true });
        fetch(`/api/redirects${queryParams}`)
            .then(response => {
                if (response.status >= 400 && response.status <= 500) {
                    throw response;
                } else {
                    return response.json();
                }
            })
            .then(data => {
                console.log(data);
                this.setState({
                    redirects: data.Redirects,
                    isLoading: false,
                    initialLoad: false,
                });
            })
            .catch(error => {
                this.setState({ isLoading: false, initialLoad: false });
                //console.log(error);
                try {
                    error.text().then(errorMessage => {
                        //console.log(errorMessage);
                        if (errorMessage.length > 0) {
                            alert(errorMessage);
                        }
                    });
                } catch { }
            });
    };

    changeRedirect = (searchTerm, redirectUrl) => {
        return new Promise((resolve, reject) => {
            let success = true;
            this.setState({ isLoading: true });
            let redirect = {};
            redirect.PartitionKey = shop;
            redirect.RowKey = searchTerm;
            redirect.RedirectUrl = redirectUrl;

            fetch(`/api/redirects${queryParams}`, {
                method: 'POST',
                body: JSON.stringify(redirect),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
            })
                .then(response => {
                    console.log(response);
                    if (response.status >= 400 && response.status <= 500) {
                        throw response;
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    console.log(data);
                    this.getRedirects();
                    resolve(success);
                })
                .catch(error => {
                    //console.log(error);
                    success = false;
                    try {
                        error.text().then(errorMessage => {
                            //console.log(errorMessage);
                            if (errorMessage.length > 0) {
                                alert(errorMessage);
                            }
                        });
                    } catch { }
                    this.setState({ isLoading: false });
                    resolve(success);
                });
        });
    };

    deleteRedirect = searchTerm => {
        this.setState({ isLoading: true });

        fetch(`/api/redirects${queryParams}&searchTerm=${searchTerm}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.status >= 400 && response.status <= 500) {
                    throw response;
                } else {
                    this.getRedirects();
                }
            })
            .catch(error => {
                //console.log(error);
                try {
                    error.text().then(errorMessage => {
                        //console.log(errorMessage);
                        if (errorMessage.length > 0) {
                            alert(errorMessage);
                        }
                    });
                } catch { }
                this.setState({ isLoading: false });
            });
    };

    handleChange = (index, field) => newValue => {
        const newRedirects = this.state.redirects.map((item, iindex) => {
            if (index !== iindex) return item;
            return { ...item, [field]: newValue };
        });

        this.setState({ redirects: newRedirects });
    };

    render() {
        const { initialLoad, isLoading, redirects } = this.state;

        return (
            <Layout>
                <Layout.Section>
                    <Card sectioned subdued>
                        <p>
                            Enter Search Terms and associated URLs to redirect those terms to
                            below. Note that changes will be immediately occurring on the live
                            site.
            </p>
                    </Card>
                    <Card title='Add New Redirect' sectioned>
                        <FormLayout>
                            <Stack alignment='leading'>
                                <Stack.Item>
                                    <TextField
                                        label='Search Term'
                                        placeholder='testterm'
                                        value={this.state.searchTermToAdd}
                                        onChange={newValue =>
                                            this.setState({ searchTermToAdd: newValue })
                                        }
                                        pattern={RegexPatterns.lowercaseandnumbers}
                                        error={
                                            regexIsInvalid(
                                                this.state.searchTermToAdd,
                                                RegexPatterns.lowercaseandnumbers,
                                            )
                                                ? 'Only lowercase letters and numbers allowed'
                                                : ''
                                        }
                                    />
                                </Stack.Item>
                                <Stack.Item fill>
                                    <TextField
                                        label='Redirect URL'
                                        placeholder='https://domain.com/page'
                                        value={this.state.redirectUrlToAdd}
                                        onChange={newValue =>
                                            this.setState({ redirectUrlToAdd: newValue })
                                        }
                                        pattern={RegexPatterns.url}
                                        error={
                                            regexIsInvalid(
                                                this.state.redirectUrlToAdd,
                                                RegexPatterns.url,
                                            )
                                                ? 'Must be a valid URL, including protocol (https)'
                                                : ''
                                        }
                                        helpText={
                                            this.state.addingRedirect
                                                ? 'Checking this URL, this may take a moment...'
                                                : ''
                                        }
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack distribution='trailing'>
                                <Button
                                    primary
                                    size='large'
                                    loading={isLoading}
                                    disabled={isLoading}
                                    onClick={() => {
                                        this.setState({ addingRedirect: true });
                                        this.changeRedirect(
                                            this.state.searchTermToAdd,
                                            this.state.redirectUrlToAdd,
                                        ).then(success => {
                                            console.log(success);
                                            if (success === true) {
                                                this.setState({
                                                    searchTermToAdd: '',
                                                    redirectUrlToAdd: '',
                                                });
                                            }
                                            this.setState({ addingRedirect: false });
                                        });
                                    }}
                                >
                                    Add
                </Button>
                            </Stack>
                        </FormLayout>
                    </Card>
                    <RedirectsList
                        redirects={redirects}
                        initalLoad={initialLoad}
                        isLoading={isLoading}
                        changeRedirect={this.changeRedirect}
                        deleteRedirect={this.deleteRedirect}
                        handleChange={this.handleChange}
                    />
                </Layout.Section>
            </Layout>
        );
    }
}
