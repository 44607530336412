import * as React from 'react';
import { Tabs } from '@shopify/polaris';

const tabs = [
  {
    id: 'main',
    content: 'Settings',
    accessibilityLabel: 'Settings',
    panelID: 'settings-content',
  },
  {
    id: 'synonyms',
    content: 'Synonyms',
    accessibilityLabel: 'Synonyms',
    panelID: 'synonyms-content',
  },
  {
    id: 'redirects',
    content: 'Redirects',
    accessibilityLabel: 'Redirects',
    panelID: 'redirects-content',
  },
  {
    id: 'boosts',
    content: 'Boosts',
    accessibilityLabel: 'Boosts',
    panelID: 'boosts-content',
  },
];

const pageToTabNum = page => {
  if (page === 'main') {
    return 0;
  } else if (page === 'synonyms') {
    return 1;
  } else if (page === 'redirects') {
    return 2;
  } else if (page === 'boosts') {
    return 3;
  }
};

const tabNumToPage = tabNum => {
  if (tabNum === 0) {
    return 'main';
  } else if (tabNum === 1) {
    return 'synonyms';
  } else if (tabNum === 2) {
    return 'redirects';
  } else if (tabNum === 3) {
    return 'boosts';
  }
};

const Menu = props => {
  return (
    <Tabs
      tabs={tabs}
      selected={pageToTabNum(props.page)}
      onSelect={selectedTab => props.pageSet(tabNumToPage(selectedTab))}
    />
  );
};

export default Menu;
