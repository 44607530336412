import * as React from 'react';
import { Spinner, Stack } from '@shopify/polaris';

const queryParams = window.location.search;

export default class Settings extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {}

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <Stack distribution='center'>
          <Spinner color='teal' />
        </Stack>
      );
    } else {
      return <p>This content is not implemented yet.</p>;
    }
  }
}
