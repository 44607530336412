import * as React from 'react';
import URLSearchParams from 'url-search-params';
import { Page } from '@shopify/polaris';
import { isNullOrUndefined } from 'util';

import Menu from './components/Menu';

import Error from './pages/Error';
import Settings from './pages/Settings';
import Redirects from './pages/Redirects';
import Synonyms from './pages/Synonyms';

//Shopify Params
const queryParams = window.location.search;
const shop = new URLSearchParams(queryParams).get('shop');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: '',
      ruleId: 0,
    };
  }

  componentDidMount() {
    if (this.state.page === '') {
      this.setState({ page: 'main' });
    }
  }

  pageSet = page => {
    this.setState({ page: page });
  };

  render() {
    const { page } = this.state;

    if (isNullOrUndefined(shop)) {
      return <Error />;
    } else if (page === 'main') {
      return (
        <Page title='Settings'>
          <Menu page={page} pageSet={this.pageSet} />
          <br />
          <Settings pageSet={this.pageSet} />
        </Page>
      );
    } else if (page === 'synonyms') {
      return (
        <Page title='Synonyms'>
          <Menu page={page} pageSet={this.pageSet} />
          <br />
          <Synonyms pageSet={this.pageSet} />
        </Page>
      );
    } else if (page === 'redirects') {
      return (
        <Page title='Redirects'>
          <Menu page={page} pageSet={this.pageSet} />
          <br />
          <Redirects pageSet={this.pageSet} />
        </Page>
      );
    } else {
      return (
        <Page title='Error'>
          <Menu page={page} pageSet={this.pageSet} ruleIdSet={this.ruleIdSet} />
          <br />
          <Error />
        </Page>
      );
    }
  }
}

export default App;
