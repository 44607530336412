import React from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/styles.css';
import App from './App';
import { AppProvider } from '@shopify/polaris';

const queryParams = window.location.search;
const shop = new URLSearchParams(queryParams).get('shop');
const shopOrigin = shop;
const apiKey = '758b3258876740e481a951b090eac21d';

ReactDOM.render(
    <AppProvider apiKey={apiKey} shopOrigin={shopOrigin} forceRedirect={true}>
        <App />
    </AppProvider>,
    document.getElementById('root'),
);
