import * as React from 'react';
import {
    Card,
    FormLayout,
    Spinner,
    Stack,
    TextField,
    Icon,
    Button,
    Layout,
} from '@shopify/polaris';

import regexIsInvalid from '../utils/regexIsInvalid';
import RegexPatterns from '../utils/RegexPatterns';

const queryParams = window.location.search;

const Synonym = props => {
    return (
        <Card.Section>
            <FormLayout>
                <Stack alignment='center'>
                    <Stack.Item fill>
                        <TextField
                            label='Search Terms'
                            value={props.synonym.Terms}
                            onChange={props.handleChange(props.index, 'Terms')}
                            helpText='Use lowercase and comma-delimit terms'
                            pattern={RegexPatterns.lowercasenumberscommaspacedash}
                            error={
                                regexIsInvalid(
                                    props.synonym.Terms,
                                    RegexPatterns.lowercasenumberscommaspacedash,
                                )
                                    ? 'Only lowercase letters, numbers, and commas allowed'
                                    : ''
                            }
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label='Preferred Term (Optional)'
                            value={props.synonym.PreferredTerm}
                            onChange={props.handleChange(props.index, 'PreferredTerm')}
                            helpText='Replaces all terms on left with this term'
                            pattern={RegexPatterns.lowercasenumbersspacedash}
                            error={
                                regexIsInvalid(
                                    props.synonym.PreferredTerm,
                                    RegexPatterns.lowercasenumbersspacedash,
                                )
                                    ? 'Only lowercase letters and numbers allowed'
                                    : ''
                            }
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Button
                            destructive
                            onClick={props.handleRemoveSynonym(props.index)}
                        >
                            <Icon source='delete' />
                        </Button>
                    </Stack.Item>
                </Stack>
            </FormLayout>
        </Card.Section>
    );
};

export default class Synonyms extends React.Component {
    state = {
        isLoading: false,
        synonyms: [],
    };

    componentDidMount() {
        this.getSynonyms();
    }

    getSynonyms = () => {
        this.setState({ isLoading: true });
        fetch(`/api/synonyms${queryParams}`)
            .then(response => {
                if (response.status >= 400 && response.status <= 500) {
                    throw response;
                } else {
                    return response.json();
                }
            })
            .then(data => {
                console.log(data);
                this.setState({
                    synonyms: data.Synonyms,
                    isLoading: false,
                });
            })
            .catch(error => {
                //console.log(error);
                try {
                    error.text().then(errorMessage => {
                        //console.log(errorMessage);
                        if (errorMessage.length > 0) {
                            alert(errorMessage);
                        }
                    });
                } catch { }
                this.setState({ isLoading: false });
            });
    };

    updateSynonyms = () => {
        console.log(`/api/synonyms${queryParams}`);
        console.log(JSON.stringify(this.state.synonyms));
        this.setState({ isLoading: true });
        fetch(`/api/synonyms${queryParams}`, {
            method: 'POST',
            body: JSON.stringify(this.state.synonyms),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                if (response.status >= 400 && response.status <= 500) {
                    throw response;
                } else {
                    return response.json();
                }
            })
            .then(data => {
                //console.log(data);
                this.setState({
                    synonyms: data.Synonyms,
                    isLoading: false,
                });
            })
            .catch(error => {
                //console.log(error);
                try {
                    error.text().then(errorMessage => {
                        //console.log(errorMessage);
                        if (errorMessage.length > 0) {
                            alert(errorMessage);
                        }
                    });
                } catch { }
                this.setState({ isLoading: false });
            });
    };

    handleAddSynonym = () => {
        this.setState({
            synonyms: this.state.synonyms.concat([
                {
                    Terms: '',
                    PreferredTerm: '',
                },
            ]),
        });
    };

    handleRemoveSynonym = index => () => {
        console.log(index);
        this.setState({
            synonyms: this.state.synonyms.filter((i, iindex) => index !== iindex),
        });
    };

    handleChange = (index, field) => newValue => {
        const newSynonyms = this.state.synonyms.map((item, iindex) => {
            if (index !== iindex) return item;
            return { ...item, [field]: newValue };
        });

        this.setState({ synonyms: newSynonyms });
    };

    render() {
        const { isLoading, synonyms } = this.state;

        if (isLoading) {
            return (
                <Layout>
                    <Layout.Section>
                        <Card sectioned subdued>
                            <p>
                                Type search terms that are all equivalent into the Search Terms
                                box (put commas in between terms), then optionally create a
                                preferred term to transform them into.
              </p>
                        </Card>
                        <Card sectioned>
                            <Stack distribution='center'>
                                <Spinner color='teal' />
                            </Stack>
                        </Card>
                    </Layout.Section>
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <Layout.Section>
                        <Card sectioned subdued>
                            <p>
                                Type search terms that are all equivalent into the Search Terms
                                box (put commas in between terms), then optionally create a
                                preferred term to transform them into.
              </p>
                        </Card>

                        <Card sectioned>
                            <Card.Section>
                                <Stack distribution='center'>
                                    <Button size='large' onClick={this.handleAddSynonym}>
                                        Add Synonym
                  </Button>
                                    <Button primary size='large' onClick={this.updateSynonyms}>
                                        Save Synonyms
                  </Button>
                                </Stack>
                            </Card.Section>
                            {synonyms.map((synonym, index) => (
                                <Synonym
                                    key={index}
                                    index={index}
                                    synonym={synonym}
                                    handleChange={this.handleChange}
                                    handleRemoveSynonym={this.handleRemoveSynonym}
                                />
                            ))}
                        </Card>
                    </Layout.Section>
                </Layout>
            );
        }
    }
}
